import { Helmet } from 'react-helmet';
import styles from './Landing.module.css';
import Hero from '../Components/Hero';
import Header from '../Components/Header';
import Explore from '../Components/Explore';
import About from '../Components/About';
import InlineCta from '../Components/InlineCta';
import Located from '../Components/Located';
import Reviews from '../Components/Reviews';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';
import InitialCookies from '../Components/InitialCookies';
import { useEffect, useState } from 'react';
import Alert from '../Components/Alert';

function Landing() {
    const initialPreference = localStorage.getItem('cookiePreferenceLaRumba');

    const [showInitialCookies, setShowInitialCookies] = useState(false);
    const [cookiePreference, setCookiePreference] = useState<string | null>(initialPreference);

    useEffect(() => {
        if (cookiePreference == null) setShowInitialCookies(true);
        else localStorage.setItem('cookiePreferenceLaRumba', cookiePreference);
    }, [cookiePreference]);

    return (
        <section className={styles.landing}>
            <Helmet>
                <title>La Rumba | Latin American Restaurant in Asheville, NC</title>
                <meta name="description" content={`La Rumba stands proud as Asheville's #1 Latin American restaurant. Dive into a rich tapestry of authentic flavors, top-tier service, and a spirited ambiance that epitomizes the best of hispanic dining. Whether you're seeking traditional Hispanic cuisine or vegan friendly options, our restaurant in Asheville has a diverse menu to cater to all palates.`} />   
                <link rel="canonical" href="https://larumbaavl.com" />
            </Helmet>
            <Header />
            <Hero />
            <About />
            <Explore />
            <InlineCta />
            <Located />
            <Reviews />
            <Contact />
            <Footer />
            {showInitialCookies && <InitialCookies close={(option: string) => {
                setCookiePreference(option);
                setShowInitialCookies(false);
            }}/>}
        </section>
    )
}

export default Landing;